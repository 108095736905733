.react-toggle--checked .react-toggle-track {
    background-color: #6ABA77; /* Change the background color when checked */
  }

  .react-toggle--focus .react-toggle-thumb {
    box-shadow: none;
  }
  
  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: none;
  }