@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Lato-Regular";
    src: url("__resources/font/Lato-Regular.ttf") format("truetype");
  }
}

@layer base {
  @font-face {
    font-family: "Lato-Light";
    src: url("__resources/font/Lato-Light.ttf") format("truetype");
  }
}

@layer base {
  @font-face {
    font-family: "Lato-Bold";
    src: url("__resources/font/Lato-Bold.ttf") format("truetype");
  }
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* scrollbar */

* {
  scrollbar-width: thin;
  scrollbar-color: #d9d9d9 transparent;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 14px;
}

/* progress stepper */
@-ms-keyframes progress-stepper-spin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
  }
}
@-moz-keyframes progress-stepper-spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes progress-stepper-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes progress-stepper-spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.pf-v5-c-progress-stepper.pf-m-show-progress {
  --pf-v5-c-progress--SuccessColor: green;
  --pf-v5-c-progress--CurrentColor: green;
  --pf-v5-c-progress-stepper__step-icon--Width: 2.25rem;

  .pf-v5-c-progress-stepper__step {
    &.pf-m-success {
      --pf-v5-c-progress-stepper__step-icon--BorderColor: var(
        --pf-v5-c-progress--SuccessColor
      );
      --pf-v5-c-progress-stepper__step-connector--before--BorderRightColor: var(
        --pf-v5-c-progress--SuccessColor
      );
      --pf-v5-c-progress-stepper__step-connector--before--BorderBottomColor: var(
        --pf-v5-c-progress--SuccessColor
      );
    }

    &.pf-m-current {
      --pf-v5-c-progress-stepper__step-icon--BorderColor: var(
        --pf-v5-c-progress--CurrentColor
      );
    }
  }

  .pf-v5-c-progress-stepper__step-icon-rotating .pf-v5-svg {
    -webkit-animation-name: progress-stepper-spin;
    -webkit-animation-duration: 1000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: progress-stepper-spin;
    -moz-animation-duration: 1000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: progress-stepper-spin;
    -ms-animation-duration: 1000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;

    animation-name: progress-stepper-spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .pf-v5-c-progress-stepper__step:not(:last-of-type)
    > .pf-v5-c-progress-stepper__step-connector::after {
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightColor: var(
      --pf-v5-c-progress--CurrentColor
    );
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomColor: var(
      --pf-v5-c-progress--CurrentColor
    );
    width: calc(var(--pf-v5-c-progress-stepper__step-percent, 0) * 1%);
    /* transition: width 500ms ease-out; */

    position: absolute;
    top: var(--pf-v5-c-progress-stepper__step-connector--before--Top);
    left: 50%;
    height: var(--pf-v5-c-progress-stepper__step-connector--before--Height);
    content: "";
    border-right: var(
        --pf-v5-c-progress-stepper__step-connector--before--BorderRightWidth
      )
      solid
      var(--pf-v5-c-progress-stepper__step-connector--before--BorderRightColor);
    border-bottom: var(
        --pf-v5-c-progress-stepper__step-connector--before--BorderBottomWidth
      )
      solid
      var(--pf-v5-c-progress-stepper__step-connector--before--BorderBottomColor);
    transform: var(
      --pf-v5-c-progress-stepper__step-connector--before--Transform
    );
  }
}
