.progress-circle {
  transform: rotate(-90deg); /* Rotate to start from the top */
}
 
.progress-circle__background {
  stroke: #ddd; /* Background color */
}
 
.progress-circle__circle {
  stroke: rgb(37 99 235); /* Progress color */
  transition: stroke-dashoffset 0.35s;
}